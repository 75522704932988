@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0%;
  padding: 0%;
  font-family: "Manrope", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Apply to your existing checkbox */
.checkbox.checkbox-xs.checkbox-primary.mr-2.square {
  border-radius: 5px;
}

.border-b-px {
  border-bottom-width: 1px;
}

.border-t-px {
  border-bottom-width: 1px;
}

.border-t-px {
  border-top-width: 1px;
}

.border-r-px {
  border-right-width: 1px;
}

.border-px {
  border-width: 1px;
}

.border-l-px {
  border-left-width: 1px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" ?><svg height="22" viewBox="0 0 48 48" width="22" xmlns="http://www.w3.org/2000/svg"><path d="M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z"/><path d="M0-.75h48v48h-48z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 50%;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #FAFAFA;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dddddd;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bbbbbb;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}

.loader::before,
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #FFF;
  animation: prixClipFix 2s linear infinite;
}

.loader::after {
  transform: rotate3d(90, 90, 0, 180deg);
  border-color: #FF3D00;
}

.loaders {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}

.loaders::before,
.loaders::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #FFF;
  animation: prixClipFix 2s linear infinite;
}

.loaders::after {
  transform: rotate3d(90, 90, 0, 180deg);
  border-color: #FF3D00;
}


@keyframes rotate {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
  }

  75%,
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
  }
}

.pier {
  /* fill: #131C30; */
  cursor: pointer;
}

.tracker-row {
  cursor: pointer;
}

.selected-row .pier {
  fill: red;
}

.selected-pier .pier {
  fill: green;
}

@layer components {
  .btn {
    text-transform: none;
  }

  /* td{
    text-transform: lowercase;
  } */
}

th {
  border-radius: 0% !important;
  z-index: 50 !important;
  background-color: #F16253 !important;
  color: white !important
}

td {
  border-radius: 0% !important;
}

.svgmap {
  font-family: "Manrope", sans-serif;
}

.svgmap path {
  cursor: pointer;
}

.svgmap path:hover {
  cursor: pointer;
  fill: rgb(238, 238, 238) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0%);
  }
}

.animate-slide-up {
  animation: slideUp 0.5s ease-out;
}